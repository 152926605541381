export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3930178556117153',
  agentpwd : '3930@7153$',
  apikey : 'fe0774817a2e3a31f008fc4ec711cbc7',
  url : 'https://mcd.afrikpay.com',
  id : 'mcd.afrikpay.com',
  accepturl : 'https://mcd.afrikpay.com/payment/web/success',
  cancelurl : 'https://mcd.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};